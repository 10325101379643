<template>
  <div>
    <div class="d-flex align-items-center justify-content-around">
      <div v-if="rowType" class="">
        <h1 class="font-16 font-weight-bold">{{payment.summary}}</h1>
        <p class="font-16">{{payment.date}}</p>
        <a v-if="payment.invoiceLink" :href="payment.invoiceLink" class="btn btn-link pt-0 pb-0">Invoice</a>
        <cleaner-invoice-links v-if="isStaff && payment.cleanerInvoiceIds" :ids="payment.cleanerInvoiceIds" />
        <a v-if="payment.receiptLink" :href="payment.receiptLink" target="_blank" class="btn btn-link pt-0 pb-0">Receipt</a>
        <a v-if="showVisitLink && payment.visitLink" :href="payment.visitLink" class="btn btn-link pt-0 pb-0">Visit</a>
      </div>
      <template v-else>
        <div>
          <h1 class="font-16 font-weight-bold">{{payment.summary}}</h1>
          <p class="font-16">{{payment.date}}</p>
          <a v-if="payment.invoiceLink" :href="payment.invoiceLink" class="btn btn-link pt-0 pb-0">Invoice</a>
          <cleaner-invoice-links v-if="isStaff && payment.cleanerInvoiceIds" :ids="payment.cleanerInvoiceIds" />
          <a v-if="payment.receiptLink" :href="payment.receiptLink" target="_blank" class="btn btn-link pt-0 pb-0">Receipt</a>
          <a v-if="showVisitLink && payment.visitLink" :href="payment.visitLink" class="btn btn-link pt-0 pb-0">Visit</a>
          <button v-if="payment.captureable" @click="captureAmount" class="btn btn-link pt-0 pb-0">Capture</button>
        </div>
        <div :class="colorClass">
          <span class="font-weight-bold">{{status}}</span>
        </div>
      </template>
      <div :class="colorClass">
        <span class="font-weight-bold">{{total}}</span>
      </div>
    </div>
    <hr>
  </div>
</template>
<script>
import api from '@/admins/api'
import CleanerInvoiceLinks from './cleaner_invoice_links.vue'
export default {
  props: {
    payment: {
      type: Object,
      required: true
    },
    showVisitLink: {
      type: Boolean,
      default: true
    }
  },
  components: { CleanerInvoiceLinks },
  computed: {
    colorClass() {
      if (this.rowType) {
        return 'green-text'
      }
      if (this.payment.clientStatus == 'on hold') {
        return 'blue-text'
      }
      if (this.payment.clientStatus == 'credit') {
        return 'blue-text'
      }
      if (this.payment.paid) {
        return 'green-text'
      }
      return 'red-text'
    },
    showCleanerInvoiceLink() {
      return this.isStaff
    },
    status() {
      return this.payment.clientStatus
    },
    total() {
      return this.payment.total
    },
    rowType() {
      return this.payment.type
    }
  },
  methods: {
    captureAmount() {
      api.captureAmount(this.payment.id).then((response) => {
        this.$emit('reload')
      })
    }
  }
}
</script>
