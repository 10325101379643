<template>
  <div class="row mx-0 my-3">
    <div class="col-sm-12">{{title}} from anywhere using the following URL: <a :class="cls" :href="url" target="_blank">{{url}}</a></div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  computed: {
    cls() {
      if (!this.isGlobalAdmin) {
        return 'disabled-selection'
      }
    }
  }
}
</script>
