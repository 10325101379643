<template>
  <span v-if="isStaff">
    <a v-for="id in ids" :href="`/cleaner_invoices/${id}`" class="btn btn-link pt-0 pb-0">Cleaner Invoice</a>
  </span>  
</template>
<script>
export default {
  props: {
    ids: {
      type: Array,
      required: true
    }
  },
}
</script>
